import request from '@/utils/request'


// 查询新-用户留言列表
export function listLeave(query) {
  return request({
    url: '/cigarette/user-leave/list',
    method: 'get',
    params: query
  })
}

export function getGroupUserInfoList(query) {
  return request({
    url: '/cigarette/user-leave/getGroupUserInfoList',
    method: 'get',
    params: query
  })
}
// 查询新-用户留言分页
export function pageLeave(query) {
  return request({
    url: '/cigarette/user-leave/page',
    method: 'get',
    params: query
  })
}

// 查询新-用户留言详细
export function getLeave(data) {
  return request({
    url: '/cigarette/user-leave/detail',
    method: 'get',
    params: data
  })
}

// 新增新-用户留言
export function addLeave(data) {
  return request({
    url: '/cigarette/user-leave/add',
    method: 'post',
    data: data
  })
}

// 修改新-用户留言
export function updateLeave(data) {
  return request({
    url: '/cigarette/user-leave/edit',
    method: 'post',
    data: data
  })
}

// 删除新-用户留言
export function delLeave(data) {
  return request({
    url: '/cigarette/user-leave/delete',
    method: 'post',
    data: data
  })
}
